<template>
  <div>
    <loading-more :fetch-data="fetchData" />
  </div>
</template>

<script>
import LoadingMore from "./components/loading-more";
import { getNewsList, getNewsInfo } from "@/api/company";

const newsColumns = [
  {
    title: "新闻标题",
    dataIndex: "title",
  },
  {
    title: "描述",
    dataIndex: "description",
  },
  {
    title: "图片链接",
    dataIndex: "imageUrl ",
  },
  {
    title: "情绪",
    dataIndex: "impact",
  },
  {
    title: "发布时间",
    dataIndex: "publishTime",
  },
  {
    title: "企查查新闻id",
    dataIndex: "qccNewsId",
  },
  {
    title: "来源",
    dataIndex: "source",
  },
  {
    title: "标签",
    dataIndex: "tags",
  },
  {
    title: "链接",
    dataIndex: "url",
  },
];

export default {
  data() {
    return {
      news: {
        columns: newsColumns,
        dataSource: [],
        info: {
          round: "",
          investorName: "",
          moneyNum: "",
          date: "",
          realTime: "",
          governAdvisor: "",
        },
      },
      ui: {},
    };
  },
  components: {
    LoadingMore,
  },
  methods: {
    async fetchData(companyId, page) {
      return await getNewsList({
        companyId,
        page: page,
        pageSize:10
      }).then(async (newList) => {
        return await getNewsInfo({ companyId }).then(
          async (impactSum) => {
            return { ...newList.data, ...{ impactList: impactSum.data } };
          }
        );
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>