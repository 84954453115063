<template>
  <div>
    <a-affix :offset-top="top">
      <a-tag color="green"> 总数：{{ total }}</a-tag>
      <a-tag
        v-for="(item, index) in impactList"
        :key="index"
        :color="impactColor[item.impact]"
      >
        {{ item.impactDesc }}：{{ item.cnt }}
      </a-tag>
    </a-affix>
    <a-list
      class="demo-loadmore-list"
      :loading="loading"
      item-layout="horizontal"
      :data-source="data"
    >
      <a-back-top />
      <div
        v-if="showLoadingMore"
        slot="loadMore"
        :style="{
          textAlign: 'center',
          marginTop: '12px',
          height: '32px',
          lineHeight: '32px',
        }"
      >
        <a-spin v-if="loadingMore" />
        <a-button v-else @click="onLoadMore"
          >加载更多</a-button
        >
      </div>
      <a-list-item slot="renderItem" slot-scope="item">
        <a-list-item-meta>
          <div slot="description">
            <div
              style="float: left"
              :class="{ 'description-style': !item.isLoadingDecription }"
            >
              {{ item.description }}
            </div>
            <a @click="showItem(item)" style="color: lightgrey; float: left">
              <div v-if="!item.isLoadingDecription">
                <a-icon type="down" />展开
              </div>
              <div v-else><a-icon type="up" />收起</div>
            </a>
          </div>
          <a-description slot="title">
            <a-description-item :span="3">
              <strong style="float: left"
                >[{{ item.publishTime | dateFormat }}]&nbsp;{{
                  item.title
                }}</strong
              >
              <news-impact style="float: left" :impact="item.impact" />
            </a-description-item>
            <a-description-item label="来源">{{
              item.source
            }}</a-description-item>
            <a-description-item label="新闻链接">
              <a :href="item.url" target="_blank">{{ item.url }}</a>
            </a-description-item>
          </a-description>
        </a-list-item-meta>
      </a-list-item>
    </a-list>
  </div>
</template>
<script>
import {
  Affix,
  BackTop,
  Descriptions,
} from "ant-design-vue";
import NewsImpact from "./news-impact";

export default {
  components: {
    ADescription: Descriptions,
    ADescriptionItem: Descriptions.Item,
    ABackTop: BackTop,
    NewsImpact,
    AAffix: Affix,
  },
  props: {
    fetchData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      lastPage: false,
      page: 0,
      top: 10,
      total: 0,
      impactList: [],
      impactColor: {
        "negative": "red",
        "positive": "green",
        "none": "lightgrey" 
      },
      isLoadingDecription: true,
      loading: true,
      loadingMore: false,
      showLoadingMore: true,
      data: [],
    };
  },
  mounted() {
    this.companyId = this.$route.params.companyId;
    this.getData((res) => {
      this.loading = false;
      this.data = res;
    });
  },
  filters: {
    dateFormat: (date) => {
      return date.substr(0, 10);
    },
  },
  methods: {
    showItem(item) {
      if (item.isLoadingDecription == undefined) {
        this.$set(item, "isLoadingDecription", true);
      } else {
        item.isLoadingDecription = !item.isLoadingDecription;
      }
    },
    getData(callback) {
      if (!this.lastPage) {
        this.fetchData(this.companyId, this.page).then((res) => {
          this.lastPage = res.last;
          this.total = res.totalElements;
          this.impactList = res.impactList;
          this.page++;
          callback(res.content);
        });
      } else {
        setTimeout(() => {
          this.loadingMore = false;
          this.$message.info("没有数据了");
        }, 2000);
      }
    },
    onLoadMore() {
      this.loadingMore = true;
      this.getData((res) => {
        this.data = this.data.concat(res);
        this.loadingMore = false;
        this.$nextTick(() => {
          window.dispatchEvent(new Event("resize"));
        });
      });
    },
  },
};
</script>
<style scoped lang="less">
.demo-loadmore-list {
  min-height: 350px;
  .description-style {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 10em;
  }
}
</style>
