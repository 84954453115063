<template>
  <div style="width: 50px; margin-left: 5px">
    <a-tag color="green" v-if="impact === 'positive'">积极</a-tag>
    <a-tag color="red" v-else-if="impact === 'negative'">消极</a-tag>
    <a-tag color="lightgrey" v-else>中立</a-tag>
  </div>
</template>

<script>
export default {
  props: {
    impact: {
      type: String,
      required: false,
      default: "none",
    },
  },
};
</script>